import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { graphql } from 'gatsby'
import GraphQLErrorList from '../../components/graphql-error-list'
import SEO from '../../components/seo'
import Layout from '../../containers/layout'
import Container from '../../components/container'
import Card from '../../components/hub/card/card'
import Category from '../../components/hub/category/category'

import * as styles from '../../components/globals-v2.module.css'
import * as start from './start.module.css'
import * as linkStyle from '../../styles/components/links.module.css'
import Search from '../../components/hub/search/search'

export const query = graphql`
    query ContentHubQuery {
        page: sanityPage(title: { eq: "Content Hub" }) {
            title
            metaTitle
            metaDescription
            metaKeywords
            metaImage {
                asset {
                    _id
                    url
                }
            }
        }

        settings: sanityContentHubSettings {
#            featuredLabel
#            featuredLatestArticleLabel
            featuredPost {
                  ... on SanityVideo {
                    _id
                }
                  ... on SanityWritten {
                    _id
                }
            }
            articlesSecondPost {
                ... on SanityVideo {
                    _id
                    _type
                    publishedAt
                    tags {
                        title
                        slug {
                            current
                        }
                    }
                    title
                    slug {
                        current
                    }
                    videoId
                    youtubePlayer
                    featureImage {
                        asset {
                            _id
                            url
                        }
                        hotspot {
                            _key
                            _type
                            x
                            y
                            height
                            width
                        }
                    }
                }
                ... on SanityWritten {
                    _id
                    _type
                    publishedAt
                    tags {
                        title
                        slug {
                            current
                        }
                    }
                    title
                    openLink
                    slug {
                        current
                    }
                    featureImage {
                        asset {
                            url
                            _id
                        }
                        hotspot {
                            _key
                            _type
                            x
                            y
                            height
                            width
                        }
                    }
                }
            }
            featuredCategory {
                id
                title
                slug {
                   current
                }
            }
            podcastsSectionLabel
            podcastsSection {
                _id
                _type
                title
                seriesTitle
                slug {
                    current
                }
                featureImage {
                    asset {
                        _id
                        url
                    }
                    hotspot {
                        _key
                        _type
                        x
                        y
                        height
                        width
                    }
                }
                appleUrl
                spotifyUrl
                youTubeUrl
            }
            lastPodcastsSectionLabel
            socialSectionLabel
            socialChannelsSection {
                url
                title
                platform
                featureImage: image {
                    asset {
                        _id
                        url
                    }
                }
            }
            pressSectionLabel
            recentSectionLabel
        }

        featuredWritten: sanityWritten(featured: {eq: true}) {
            _id
            _type
            publishedAt
            tags {
                title
                slug {
                    current
                }
            }
            title
            openLink
            slug {
                current
            }
            featureImage {
                asset {
                    url
                    _id
                }
                hotspot {
                    _key
                    _type
                    x
                    y
                    height
                    width
                }
            }
        }

#        featuredAudio: sanityAudio(featured: {eq: true}) {
#            _id
#            _type
#            publishedAt
#            tags {
#                title
#                slug {
#                    current
#                }
#            }
#            title
#            slug {
#                current
#            }
#            featureImage {
#                asset {
#                    _id
#                    url
#                }
#            }
#        }

        featuredVideo: sanityVideo(featured: {eq: true}) {
            _id
            _type
            publishedAt
            tags {
                title
                slug {
                    current
                }
            }
            title
            slug {
                current
            }
            videoId
            youtubePlayer
            featureImage {
                asset {
                    _id
                    url
                }
                hotspot {
                    _key
                    _type
                    x
                    y
                    height
                    width
                }
            }
        }

        press: allSanityPost(limit: 2, sort: {fields: publishedAt, order: DESC}, filter: {categories: {in: ["press"]}}) {
            nodes {
                _type
                id
                publishedAt
                mainImage {
                    asset {
                        url
                        _id
                    }
                    alt
                    hotspot {
                        _key
                        _type
                        x
                        y
                        height
                        width
                    }
                }
                tags {
                    title
                    slug {
                        current
                    }
                }
                title
                link
                timeReads
            }
        }

        written: allSanityWritten(sort: {order: DESC, fields: publishedAt}) {
            nodes {
                _id
                _type
                publishedAt
                tags {
                    title
                    slug {
                        current
                    }
                }
                title
                blurb
                _rawContent
                openLink
                slug {
                    current
                }
                featureImage {
                    asset {
                        url
                        _id
                    }
                    hotspot {
                        _key
                        _type
                        x
                        y
                        height
                        width
                    }
                }
                metaViews
            }
        }

#        audio: allSanityAudio(sort: {order: DESC, fields: publishedAt}) {
#            nodes {
#                _id
#                _type
#                publishedAt
#                tags {
#                    title
#                    slug {
#                        current
#                    }
#                }
#                title
#                slug {
#                    current
#                }
#                categories {
#                    title
#                    slug {
#                        current
#                    }
#                }
#                series {
#                    title
#                    slug {
#                        current
#                    }
#                }
#                featureImage {
#                    asset {
#                        _id
#                        url
#                    }
#                }
#                appleUrl
#                spotifyUrl
#                youTubeUrl
#                metaViews
#                timeReads
#            }
#        }

        video: allSanityVideo( sort: {order: DESC, fields: publishedAt}) {
            nodes {
                _id
                _type
                publishedAt
                tags {
                    title
                    slug {
                        current
                    }
                }
                title
                slug {
                    current
                }
                series {
                    _id
                    slug {
                        current
                    }
                    title
                }
                videoId
                youtubePlayer
                featureImage {
                    asset {
                        _id
                        url
                    }
                    hotspot {
                        _key
                        _type
                        x
                        y
                        height
                        width
                    }
                }
                appleUrl
                spotifyUrl
                youTubeUrl
                metaViews
                timeReads
            }
        }

        short: allSanityShort(limit: 4, sort: {order: DESC, fields: publishedAt}) {
            nodes {
                url
                title
                platform
                featureImage: 
                image {
                    asset {
                        _id
                        url
                    }
                    hotspot {
                        _key
                        _type
                        x
                        y
                        height
                        width
                    }
                }
            }
        }

        tags: allSanityTag(filter: { display: { ne: false } }) {
            nodes {
                display
                title
                position
                slug {
                    current
                }
            }
        }
    }
`

const ContentHub = props => {
  const { data, errors } = props

  const [state, setState] = useState({
    featuredWritten: null,
    featuredAudio: null,
    featuredVideo: null,
    writtenArticles: null,
    audioPosts: null,
    // topPodcasts: null,
    topVideoPodcasts: null,
    podcasts: null,
    videoPosts: null,
    socialPosts: null,
    shortPosts: null,
    pressPosts: null,
    allTags: null
  })

  const [displayedPosts, setDisplayedPosts] = useState([])
  const [lastFeatured, setLastFeatured] = useState(null)
  const [latestPodcasts, setLatestPodcasts] = useState(null)
  // const [latestPostByTag, setLatestPostByTag] = useState([])
  // const [topViews, setTopViews] = useState(null)
  const [mostRecent, setMostRecent] = useState(null)
  // const [latestArticle, setLatestArticle] = useState(null)
  const [filteredByCategoryPosts, setFilteredByCategoryPosts] = useState(null)
  const [mostRecentCount, setMostRecentCount] = useState(4)

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = data.page

  useMemo(() => {
    if (data) {
      setState((prevState) => ({
        featuredWritten: prevState.featuredWritten || data.featuredWritten,
        featuredAudio: prevState.featuredAudio || data.featuredAudio,
        featuredVideo: prevState.featuredVideo || data.featuredVideo,
        writtenArticles: prevState.writtenArticles || data.written.nodes,
        // audioPosts: prevState.audioPosts || data.audio.nodes,
        topPodcasts: prevState.topPodcasts || data.topPodcasts,
        topVideoPodcasts: prevState.topVideoPodcasts || data.topVideoPodcasts,
        podcasts: prevState.podcasts || data.settings.podcastsSection,
        videoPosts: prevState.videoPosts || data.video.nodes,
        socialPosts: prevState.socialPosts || data.settings.socialChannelsSection,
        shortPosts: prevState.shortPosts || data.short.nodes,
        pressPosts: prevState.pressPosts || data.press.nodes,
        allTags: prevState.allTags || data.tags.nodes
      }))
    }
  }, [data])

  /**
   * Last Featured
   */
  useEffect(() => {
    const allFeatured = [state.featuredWritten, state.featuredVideo].filter(Boolean).filter(node => !node._id.startsWith('drafts.'))
    if (allFeatured.length === 0) {
      setLastFeatured(null)
      return
    }

    allFeatured.sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt))

    if (allFeatured[0]) {
      setDisplayedPosts((prevPosts) => {
        if (prevPosts.length === 0) {
          return [allFeatured[0]]
        } else {
          return [...prevPosts, allFeatured[0]]
        }
      })
    }

    const featuredField = [...data.written.nodes, ...data.video.nodes].filter(post => post._id === data.settings?.featuredPost?._id)[0]
    setLastFeatured(featuredField || allFeatured[0])
  }, [state.featuredWritten, state.featuredVideo])

  /**
   * Funding announcement
   */
  useEffect(() => {
    const getLatestFilteredByCategoryPosts = (writtenArticles, videoPosts) => {
      const allPosts = [writtenArticles, videoPosts].filter(Boolean).flat().filter(node => !node._id.startsWith('drafts.'))
      if (allPosts.length === 0) {
        return []
      }
      const categoryToFilter = data.settings?.featuredCategory?.title ?? 'Funding News'
      const filteredByCategoryPosts = allPosts.filter((post) => post.tags.find((tag) => tag.title === categoryToFilter))

      filteredByCategoryPosts.sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt))
      return filteredByCategoryPosts
    }

    const allFilteredByCategoryPosts = getLatestFilteredByCategoryPosts(state.writtenArticles, state.videoPosts)

    if (allFilteredByCategoryPosts.length > 1) {
      const postsToShow = allFilteredByCategoryPosts.slice(0, data.settings.articlesSecondPost ? 1 : 2)
      setFilteredByCategoryPosts(postsToShow)
      setDisplayedPosts(prevDisplayedPosts => [...prevDisplayedPosts, ...postsToShow])
    } else if (allFilteredByCategoryPosts.length === 1) {
      setFilteredByCategoryPosts([allFilteredByCategoryPosts[0]])
      setDisplayedPosts(prevDisplayedPosts => [...prevDisplayedPosts, allFilteredByCategoryPosts[0]])
    } else {
      setFilteredByCategoryPosts([])
    }
  }, [state.allTags, state.writtenArticles, state.videoPosts])

  /**
   * Top Podcasts
   */
  useEffect(() => {
    if (!state.videoPosts) {
      setLatestPodcasts(null)
      return
    }

    const includeSeriesTitles = ['logan-bartlett', 'unsupervised-learning']

    const allContent = [...state.videoPosts].filter(node => {
      return (
        !node._id.startsWith('drafts.') &&
        includeSeriesTitles.includes(node.series.slug.current)
      )
    })

    const groupedBySeries = includeSeriesTitles.reduce((acc, seriesTitle) => {
      acc[seriesTitle] = allContent
        .filter(node => node.series.slug.current === seriesTitle)
        .sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt))
      return acc
    }, {})

    const result = []
    let index = 0

    while (result.length < 3) {
      includeSeriesTitles.forEach(seriesTitle => {
        const seriesContent = groupedBySeries[seriesTitle]
        if (seriesContent[index]) {
          result.push(seriesContent[index])
        }
      })
      index++
    }

    if (Array.isArray(result) && result.length > 0) {
      setDisplayedPosts((prevPosts) => {
        if (prevPosts.length === 0) {
          return [...result]
        } else {
          return [...prevPosts, ...result]
        }
      })
    }

    setLatestPodcasts(result.slice(0, 3))
  }, [state.videoPosts])

  /**
   * Top Views
   */
  // useEffect(() => {
  //   const allPosts = [state.writtenArticles, state.videoPosts].filter(Boolean).flat().filter(node => !node._id.startsWith('drafts.'))
  //   if (allPosts.length === 0) {
  //     setTopViews(null)
  //     return
  //   }
  //
  //   allPosts.sort((a, b) => b.metaViews - a.metaViews)
  //
  //   const topViewsPosts = allPosts.slice(0, 3)
  //
  //   if (topViewsPosts.length > 0) {
  //     setDisplayedPosts((prevPosts) => {
  //       if (prevPosts.length === 0) {
  //         return [...topViewsPosts]
  //       } else {
  //         return [...prevPosts, ...topViewsPosts]
  //       }
  //     })
  //   }
  //
  //   setTopViews(topViewsPosts)
  // }, [state.writtenArticles, state.videoPosts])

  /**
   * By Sector
   */
  // useEffect(() => {
  //   if (!state.allTags || !state.writtenArticles || !state.videoPosts) {
  //     setLatestPostByTag([])
  //     return
  //   }
  //
  //   const getPostByTag = (tagSlug) => {
  //     const allPosts = [
  //       ...state.writtenArticles,
  //       ...state.videoPosts
  //     ]
  //     const filteredPosts = allPosts.filter(post =>
  //       post.tags.some(tag => tag?.slug?.current === tagSlug)
  //     )
  //     const sortedPosts = filteredPosts.sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt))
  //
  //     if (sortedPosts[0]) {
  //       setDisplayedPosts((prevPosts) => {
  //         if (prevPosts.length === 0) {
  //           return [sortedPosts[0]]
  //         } else {
  //           return [...prevPosts, sortedPosts[0]]
  //         }
  //       })
  //     }
  //
  //     return sortedPosts[0]
  //   }
  //
  //   const newLatestPosts = state.allTags.map((tag) => {
  //     return getPostByTag(tag.slug.current)
  //   }).filter(Boolean).filter(node => !node._id.startsWith('drafts.'))
  //
  //   if (newLatestPosts.length > 0) {
  //     setDisplayedPosts((prevPosts) => {
  //       if (prevPosts.length === 0) {
  //         return [...newLatestPosts]
  //       } else {
  //         return [...prevPosts, ...newLatestPosts]
  //       }
  //     })
  //   }
  //
  //   setLatestPostByTag(newLatestPosts)
  // }, [state.allTags, state.writtenArticles, state.videoPosts])

  /**
   * Most Recent
   */
  useEffect(() => {
    function getMostRecentPosts (writtenArticles, videoPosts, latestToExclude) {
      const allPosts = [writtenArticles, videoPosts].filter(Boolean).flat().filter(node => !node._id.startsWith('drafts.'))
      if (allPosts.length === 0) {
        return null
      }

      const filteredPosts = allPosts.filter(post => {
        if (!post || !post._id) return false
        return !displayedPosts.some(displayedPost => displayedPost && displayedPost._id === post._id)
      })

      if (filteredPosts.length === 0) {
        return null
      }

      filteredPosts.sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt))
      // const excludedLatestArticlePosts = filteredPosts.filter((post) => post._id !== latestToExclude._id)
      return filteredPosts.slice(0, mostRecentCount)
    }

    // const getLatestArticle = (posts) => {
    //   const allPosts = posts.filter(Boolean).flat().filter(node => !node._id.startsWith('drafts.'))
    //   if (allPosts.length === 0) {
    //     return null
    //   }
    //
    //   const filteredPosts = allPosts.filter(post => {
    //     if (!post || !post._id) return false
    //     return !displayedPosts.some(displayedPost => displayedPost && displayedPost._id === post._id)
    //   })
    //
    //   if (filteredPosts.length === 0) {
    //     return null
    //   }
    //
    //   filteredPosts.sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt))
    //   return filteredPosts[0]
    // }

    // const foundLatestArticle = getLatestArticle(state.writtenArticles)
    const recentPosts = getMostRecentPosts(state.writtenArticles, state.videoPosts)
    // setLatestArticle(foundLatestArticle)
    setMostRecent(recentPosts)
  }, [displayedPosts, mostRecentCount, state.writtenArticles, state.videoPosts])

  const handleMoreMostRecent = useCallback(() => {
    setMostRecentCount(mostRecentCount + 4)
  }, [mostRecent, mostRecentCount])

  if (!page) {
    throw new Error(
      'Missing "Content Hub" page data. Open the studio at http://localhost:3333 and add "Content Hub" page data and restart the development server.'
    )
  }

  return (
    <Layout hideContactBar hideAnnouncements logoOverrideUrl='/content-hub/' version='1'>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <SEO
        title={page.metaTitle || page.title} description={page.metaDescription}
        keywords={page.metaKeywords.split(',')} imageFB={page.metaImage}
      />

      <Container size='large' class={`${styles.container} ${styles.contentHubContainer}`}>
        <section className={start.menuWtSearch}>
          <Category nodes={data.tags.nodes} type='category' current='all' />
          <Search />
        </section>

        <section className={`${start.twoColumns} featuredSection`}>
          <div>
            <div className={start.heading}>{data.settings.featuredLabel ?? 'Featured'}</div>
            <div>
              {lastFeatured !== null && lastFeatured._id && (
                <Card
                  type='simple' options={{ tags: true, imgSizes: [980, 513], imgStyles: { maxHeight: 508 + 'px' } }}
                  section='featured' data={lastFeatured}
                />
              )}
            </div>
          </div>
          <div className={start.newFeatured}>
            {filteredByCategoryPosts?.length > 0 && (
              <div>
                <div className={start.heading}>Articles</div>
                <div className={start.podcastsMiniWrapper}>
                  {filteredByCategoryPosts.map((post) => (
                    <Card
                      key={post._id}
                      type='simple'
                      options={{ tags: true, imgSizes: [348, 203], imgStyles: { maxHeight: '196px' } }}
                      section='featuredLatest'
                      data={post}
                    />
                  ))}
                  {data.settings.articlesSecondPost && (
                    <Card type='simple' options={{ tags: true, imgSizes: [348, 203], imgStyles: { maxHeight: 196 + 'px' } }} section='featuredLatest' data={data.settings.articlesSecondPost} />
                  )}
                </div>
              </div>
            )}
            {/* {latestArticle !== null && latestArticle._id && ( */}
            {/*  <div> */}
            {/*    <div className={start.heading}>{data.settings.featuredLatestArticleLabel ?? 'Latest Article'}</div> */}
            {/*    <div className={start.podcastsMiniWrapper}> */}
            {/*      <Card */}
            {/*        type='simple' options={{ tags: true, imgSizes: [348, 203], imgStyles: { maxHeight: 196 + 'px' } }} */}
            {/*        section='featuredLatest' data={latestArticle} */}
            {/*      /> */}
            {/*    </div> */}
            {/*  </div> */}
            {/* )} */}
          </div>
        </section>

        <div className={start.allPodcasts}>
          <section className={`${start.separator} ${start.podcastsSection}`}>
            {state.podcasts?.length > 0 && (
              <div className={`${start.oneColumn} ${start.separator} ${start.podcastsWrapper}`}>
                <div className={start.heading}>Podcasts</div>
                <div className={`${start.sectionWrapper} ${start.podcastsWrapper}`}>
                  {state.podcasts.map((podcast, index) => (
                    <Card key={index} options={{ tags: true }} type='simpleMedia' section='podcasts' data={podcast} />
                  ))}
                </div>
              </div>
            )}
          </section>

          <section className={`${start.separator} ${start.podcastsSection}`}>
            {latestPodcasts !== null && (
              <div className={`${start.oneColumn} ${start.separator}`}>
                <div className={start.heading}>{data.settings.lastPodcastsSectionLabel ?? 'NEWEST EPISODES'}</div>
                <div className={`${start.sectionWrapper} ${start.podcastsWrapper} ${start.featuredPodcastsWrapper}`}>
                  {latestPodcasts.map((audio, index) => (
                    <Card key={index} options={{ tags: true, noHover: true, showType: false, imgSizes: [390, 219] }} type='simpleAudio' section='podcastsSection' data={audio} />
                  ))}
                </div>
              </div>
            )}
          </section>
        </div>

        <section className={`${start.twoColumns} ${start.socialSection} ${start.separator}`}>
          <div>
            <div className={start.heading}>{data.settings.socialSectionLabel ?? 'Social'}</div>
            <div className={`${start.columnRowWrapper} ${start.socialWrapper}`}>
              {state.socialPosts !== null && (
                state.socialPosts.map((social, index) => (
                  <Card page='content-hub' key={index} type='social' section='chSocial' data={social} />
                ))
              )}
            </div>
          </div>

          {state.pressPosts?.length > 0 && (
            <div className={start.pressSection}>
              <div className={start.heading}>{data.settings.pressSectionLabel ?? 'Press'}</div>
              <div className={start.columnWrapper}>
                {state.pressPosts.map((press, index) => (
                  <Card
                    key={index} options={{ tags: true, reads: true, newTab: true, imgStyles: { maxHeight: 196 + 'px' } }}
                    type='simple' section='pressSection' data={press}
                  />
                ))}
              </div>
            </div>
          )}
        </section>

        <section className={`${start.twoColumnsGrid} ${start.separator}`}>
          <div className={start.heading}>{data.settings.recentSectionLabel ?? 'Most Recent'}</div>
          <div className={`${start.sectionWrapper} ${start.recentWrapper}`}>
            {mostRecent !== null && (
              mostRecent.map((article, index) => (
                <Card
                  key={index} section='mostRecent' type='simple'
                  options={{ imgStyles: { maxHeight: 335 + 'px' }, imgSizes: [596, 335] }} data={article}
                />
              ))
            )}
          </div>
          {mostRecent !== null && mostRecentCount <= mostRecent.length && (
            <div className={start.moreButton}>
              <a className={linkStyle.linkPlus} onClick={handleMoreMostRecent}>More
                <span>
                  <div className={linkStyle.iconPlus} />
                </span>
              </a>
            </div>
          )}
        </section>
      </Container>
    </Layout>
  )
}

export default ContentHub
